












































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

export default Vue.extend({
  components: {
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
      promptDelete: null as string|null,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      list: 'business/Pages/List',
    }),
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    pages(): ApiResource.Profile.Page[] {
      return this.list(this.business.id).sort(
        (a: ApiResource.Profile.Page, b: ApiResource.Profile.Page) => a.weight - b.weight,
      );
    },
    isAbovePageCountLimit(): boolean {
      return this.pages.length >= this.business.limits.pages;
    },
  },
  watch: {
    business: {
      immediate: true,
      handler(to) {
        if (to) this.$store.dispatch('business/Pages/List', { BUSINESS_ID: to.id });
      },
    },
  },
  methods: {
    async deletePage(page: ApiResource.Profile.Page) {
      this.deleting = true;

      await this.$store.dispatch('business/Pages/Delete', {
        BUSINESS_ID: this.business.id,
        PAGE_ID: page.id,
      });

      this.deleting = false;
    },
  },
});
